import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Link } from 'gatsby';

import * as styles from '../styles/flex/other/policy.module.css';

function PrivacyPolicy() {
    return (
        <Layout>
            <Seo title="Privacy Policy" description="This page describes how we might use your information." />
            <section className={styles.Container}>
                <h1>Privacy Notice</h1>
                <p>Last updated September 14, 2021</p>
                <p>
                    <br/>
                    Thank you for choosing to be apart of this community. We are committed to protecting 
                    you personal information and your right to privacy. If you have any questions or concerns 
                    about this privacy notice or our practices with regard to your personal information, 
                    please contact us at gtamaxprofit@gmail.com.
                    <br/><br/>
                    This privacy notice describes how we might use your information if you:
                    <br/><br/>
                    <ul>
                        <li>Visit our website at <Link to="/">http://www.gtamaxprofit.com</Link></li>
                        <li>Engage with us in other related ways - including any sales, marketing, or events</li>
                    </ul>
                    <br/>
                    In this privacy notice, if we refer to:
                    <br/><br/>
                    <ul>
                        <li>"<b>Website</b>," we are referring to any website or ours that references or links to this policy</li>
                        <li>"<b>Services</b>," we are referring to our website, and other related services, including 
                        any sales, marketing, or events</li>
                    </ul>
                    <br/>
                    The purpose of this privacy notice is to explain to you in the clearest way possible what 
                    information we collect, how we use it, and what rights you have in relation to it. If 
                    there are any terms in this privacy notice that you do not agree with, please discontinue use of 
                    our services immediately.
                    <br/><br/>
                    <b>
                        Please read this privacy notice carefully, as it will help you understand what we do the 
                        information that we collect.
                    </b>
                    <br/><br/><br/>
                </p>
                <h1>Table of Contents</h1>
                <p>
                    <br/>
                    <ol>
                        <li><Link to="#info-collect">WHAT INFORMATION DO WE COLLECT?</Link></li>
                        <li><Link to="#info-use">HOW DO WE USE YOUR INFORMATON?</Link></li>
                        <li><Link to="#info-share">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Link></li>
                        <li><Link to="#cookies">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</Link></li>
                        <li><Link to="#social-logins">HOW DO WE HANDLE YOU SOCIAL LOGINS?</Link></li>
                        <li><Link to="#3p-websites">WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</Link></li>
                        <li><Link to="#info-retain">HOW LONG DO WE KEEP YOUR INFORMATION?</Link></li>
                        <li><Link to="#info-safe">HOW DO WE KEEP YOUR INFORMATION SAFE?</Link></li>
                        <li><Link to="#info-minors">DO WE COLLECT DATA FROM MINORS?</Link></li>
                        <li><Link to="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS?</Link></li>
                        <li><Link to="#DNT">CONTROLS FOR DO NOT TRACK FEATURES</Link></li>
                        <li><Link to="#CA-residents">DO CALIFORNIA RESIDENTS HAVE SPECIFIC POLICY RIGHTS?</Link></li>
                        <li><Link to="#policy-updates">DO WE MAKE UPDATES TO THIS NOTICE?</Link></li>
                        <li><Link to="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Link></li>
                        <li><Link to="#request">HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</Link></li>
                    </ol>
                    <br/><br/><br/>
                </p>
                <h1 id="info-collect">1. What information do we collect?</h1>
                <br/>
                <h2>Personal information you disclose to us</h2>
                <p>
                    <br/>
                    <b>In Short:</b> We collect personal information that you provide to us.
                    <br/><br/>
                    We collect personal information that you voluntarily provide to us when you register on the website, 
                    express an interest in obtaining information about us or our services, when you participate in 
                    activities on the website or otherwise when you contact us.
                    <br/><br/>
                    The personal information that we collect depends on the context of your interactions with us and the 
                    website, the choices you make and the products and features you use. The personal information we 
                    collect may include the following.
                    <br/><br/>
                    <b>Personal Information Provided by You.</b> We collect names; phone numbers; email addresses; billing 
                    addresses; contact  or authentication data; passwords; usernames; debit/credit card numbers; and other 
                    similiar information.
                    <br/><br/>
                    <b>Payment Data.</b> We may collect data necessary to process you payment if you make purchases, such as 
                    your payment instrument number (such as credit card number), and the security code associated with your 
                    payment instrument. All payment data is stored by Stripe. You may find their privacy notice link here: <Link to="https://stripe.com/privacy" target="_blank">https://stripe.com/privacy</Link>.
                    <br/><br/>
                    <b>Socail Media Login Data.</b> We may provide you with the option to register with us using your existing 
                    social media account details, like your Google account. If you choose to register in this way, we will 
                    collect the information described in the section called "<Link to="#social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Link>" below.
                    <br/><br/>
                    All personal information that you provide us must be true, complete and accurate, and you must notify 
                    us of any changes to such personal information.
                    <br/><br/>
                </p>
                <h2>Information automatically collected</h2>
                <p>
                    <br/>
                    <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or browser and device 
                    characteristics — is collected automatically when you visit our website.
                    <br/><br/>
                    We automatically collect certain information when you visit, use or navigate the website. This information 
                    does not reveal your specific identity (like your name or contact information) but may include device and 
                    usage information, such as your IP address, browser and device characteristics, operating system, language 
                    preferences, referring URLs, device name, country, location, information about how and when you use our website 
                    and other technical information. This information is primarily needed to maintain the security and operation of our 
                    website, and for our internal analytics and reporting purposes.
                    <br/><br/>
                    Like many businesses, we also collect information through cookies and similar technolgies. You can find out more 
                    about this in our Cookie Notice: <Link to="http://www.gtamaxprofit.com/cookie-policy">http://www.gtamaxprofit.com/cookie-policy</Link>.
                    <br/><br/>
                    The information we collect includes:
                    <br/><br/>
                    <ul>
                        <li>
                            Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information 
                            our servers automatically collect when you access or use our website and which we record in log files. 
                            Depending on how you interact with us, this log data may include your IP address, device information, 
                            browser type and settings and information about your activity in the website (such as the date/time 
                            stamps associated with your usage, pages and files viewed, searches and other actions you take such as 
                            which features you use), device event information (such as system activity, error reports (sometimes 
                            called 'crash dumps') and hardware settings).
                        </li>
                        <li>
                            Device Data. We collect device data such as information about your computer, phone, tablet or other 
                            device you use to access the website. Depending on the device used, this device data may include 
                            information such as your IP address (or proxy server), device and application identification numbers, 
                            location, browser type, hardware model Internet service provider and/or mobile carrier, operating system 
                            and system configuration information.
                        </li>
                        <li>
                            Location Data. We collect location data such as information about your device's location, which can be 
                            either precise or imprecise. How much information we collect depends on the type and settings of the 
                            device you use to access the website. For example, we may use GPS and other technologies to collect 
                            geolocation data that tells us your current location (based on your IP address). You can opt out of 
                            allowing us to collect this information either by refusing access to the information or by disabling 
                            your Location setting on your device. Note however, if you choose to opt out, you may not be able to 
                            use certain aspects of the Services.
                        </li>
                    </ul>
                    <br/><br/>
                </p>
                <h1 id="info-use">2. How do we use your information?</h1>
                <p>
                    <br/>
                    <b>In Short.</b> We process your information for purposes based on legitimate business interests, the 
                    fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
                    <br/><br/>
                    We use personal information collected via our website for a variety of business purposes described 
                    below. We process your personal information for these purposes in reliance on our legitimate business 
                    interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance 
                    with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose 
                    listed below.
                    <br/><br/>
                    We use information we collect or recieve:
                    <br/><br/>
                    <ul>
                        <li>
                            <b>To facilitate account creation and logon process.</b> If you choose to link your account with us 
                            to a third-party account (such as your Google or Facebook account), we use the information you allowed 
                            us to collect from those third parties to facilitate account creation and logon process for the performance 
                            of the contract. See the section below headed "<Link to="#social-logins">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Link>" 
                            for further information.
                        </li>
                        <li>
                            <b>To manage user accounts.</b> We may use your information for the purposes of managing our account and 
                            keeping it in working order.
                        </li>
                        <li>
                            <b>To send administrative information to you.</b> We may use your personal information to send you product, 
                            service and new feature information and/or information about changes to our terms, conditions, and policies.
                        </li>
                        <li>
                            <b>To protect our Services.</b> We may use your information as part of our efforts to keep our website 
                            safe and secure (for example, for fraud monitoring and prevention).
                        </li>
                        <li>
                            <b>
                                To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory 
                                requirements or in connection with our contract.
                            </b>
                        </li>
                        <li>
                            <b>To respond to legal requests and prevent harm.</b> If we receive a subpoena or other legal request, we may 
                            need to inspect the data we hold to determine how to respond.
                        </li>
                        <li>
                            <b>To send you marketing and promotional communications.</b>  We and/or our third-party marketing partners may 
                            use the personal information you send to us for our marketing purposes, if this is in accordance with your 
                            marketing preferences. For example, when expressing an interest in obtaining information about us or our website, 
                            subscribing to marketing or otherwise contacting us, we will collect personal information from you. You can 
                            opt-out of our marketing emails at any time (see the "<Link to="#privacy-rights">WHAT ARE YOUR PRIVACY RIGHTS</Link>" 
                            below).                            
                        </li>
                        <li>
                            <b>Deliver targeted advertising to you.</b>  We may use your information to develop and display personalized content 
                            and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its 
                            effectiveness. For more information see our Cookie Notice: <Link to="http://www.gtamaxprofit.com/cookie-policy">http://www.gtamaxprofit.com/cookie-policy</Link>.
                        </li>
                    </ul>
                    <br/><br/>
                </p>
                <h1 id="info-share">3. Will your information be shared with anyone?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We only share information with your consent, to comply with laws, to provide you with services, to protect your 
                    rights, or to fulfill business obligations.
                    <br/><br/>
                    We may process or share your data that we hold based on the following legal basis:
                    <br/>
                    More specifically, we may need to process your data or share your personal information in the following situations:
                    <br/><br/>
                    <ul>
                        <li>
                            <b>Business Transfers.</b> We may share or transfer your information in connection with, or during negotiations of, 
                            any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                        </li>
                    </ul>
                    <br/><br/>
                </p>
                <h1 id="cookies">4. Do we use cookies and other tracking technolgies?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We may use cookies and other tracking technologies to collect and store your information.
                    <br/><br/>
                    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. 
                    Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice: <Link to="http://www.gtamaxprofit.com/cookie-policy">http://www.gtamaxprofit.com/cookie-policy</Link>.
                    <br/><br/><br/>
                </p>
                <h1 id="social-logins">5. How do we handle your social logins?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> If you choose to register or log in to our services using a social media account, we may have access to certain 
                    information about you.
                    <br/><br/>
                    Our website offers you the ability to register and login using your third-party social media account details (like your Facebook 
                    or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. 
                    The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email 
                    address, friends list, profile picture as well as other information you choose to make public on such social media platform.
                    <br/><br/>
                    We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear 
                    to you on the relevant website. Please note that we do not control, and are not responsible for, other uses of your personal information 
                    by your third-party social media provider. We recommend that you review their privacy notice to understand how they collect, use and 
                    share your personal information, and how you can set your privacy preferences on their sites and apps.
                    <br/><br/><br/>
                </p>
                <h1 id="3p-websites">6. What is our stance on third party websites?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We are not responsible for the safety of any information that you share with third-party providers who advertise, 
                    but are not affiliated with, our Website.
                    <br/><br/>
                    The website may contain advertisements from third parties that are not affiliated with us and which may link to other websites, 
                    online services or mobile applications. We cannot guarantee the safety and privacy of data you provide to any third parties. Any data 
                    collected by third parties is not covered by this privacy notice. We are not responsible for the content or privacy and security practices 
                    and policies of any third parties, including other websites, services or applications that may be linked to or from the website. You 
                    should review the policies of such third parties and contact them directly to respond to your questions.
                    <br/><br/><br/>
                </p>
                <h1 id="info-retain">7. How long do we keep your information?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise 
                    required by law.
                    <br/><br/>
                    We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer 
                    retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require 
                    us keeping your personal information for longer than the period of time in which users have an account with us.
                    <br/><br/>
                    When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, 
                    or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store 
                    your personal information and isolate it from any further processing until deletion is possible.
                    <br/><br/><br/>
                </p>
                <h1 id="info-safe">8. How do we keep your information safe?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We aim to protect your personal information through a system of organizational and technical security measures.
                    <br/><br/>
                    We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information 
                    we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information 
                    storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized 
                    third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our 
                    best to protect your personal information, transmission of personal information to and from our website is at your own risk. You should only 
                    access the website within a secure environment.
                    <br/><br/><br/>
                </p>
                <h1 id="info-minors">9. Do we collect information from minors?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> We do not knowingly collect data from or market to children under 18 years of age.
                    <br/><br/>
                    We do not knowingly solicit data from or market to children under 18 years of age. By using the websiteWe do not knowingly solicit data from or 
                    market to children under 18 years of age. By using the website. If we learn that personal information from users less than 18 years of age has 
                    been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware 
                    of any data we may have collected from children under age 18, please contact us at gtamaxprofit@gmail.com.
                    <br/><br/><br/>
                </p>
                <h1 id="privacy-rights">10. What are your privacy rights?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> In some regions, such as the European Economic Area (EEA) and United Kingdom (UK), you have rights that allow you greater 
                    access to and control over your personal information. You may review, change, or terminate your account at any time.
                    <br/><br/>
                    In some regions (like the EEA and UK), you have certain rights under applicable data protection laws. These may include the right (i) to 
                    request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing 
                    of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object 
                    to the processing of your personal information. To make such a request, please use the <Link to="#contact">contact details</Link>  provided 
                    below. We will consider and act upon any request in accordance with applicable data protection laws.
                    <br/><br/>
                    If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note 
                    however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal 
                    information conducted in reliance on lawful processing grounds other than consent.
                    <br/><br/>
                    If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to 
                    complain to your local data protection supervisory authority. You can find their contact details here: <Link to="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</Link>.
                    <br/><br/>
                    If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <Link to="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</Link>.
                    <br/><br/>
                    If you have questions or comments about your privacy rights, you may email us at gtamaxprofit@gmail.com.
                    <br/><br/>
                </p>
                <h2>Account Information</h2>
                <p>
                    <br/>
                    If you would at any time like to review or change the information in your account or terminate your account, you can:
                    <br/><br/>
                    <ul>
                        <li>Log in to your account settings and update your user account.</li>
                    </ul>
                    <br/>
                    Upon your request to terminate your account, we will deactivate or delete your account and information from our active 
                    databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any 
                    investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
                    <br/><br/>
                    <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by default. If you prefer, you can usually 
                    choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could 
                    affect certain features or services of our website. To opt-out of interest-based advertising by advertisers on our website 
                    visit <Link to="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</Link> For further information, please see our 
                    Cookie Notice: <Link to="http://www.gtamaxprofit.com/cookie-policy">http://www.gtamaxprofit.com/cookie-policy</Link>.
                    <br/><br/>
                    <b>Opting out of email marketing:</b> You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe 
                    link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email 
                    list — however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration 
                    and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
                    <br/><br/>
                    <ul>
                        <li>
                            Access your account settings and update your preferences.
                        </li>
                    </ul>
                    <br/><br/>
                </p>
                <h1 id="DNT">11. Controls for do-not-track features</h1>
                <p>
                    <br/>
                    Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can 
                    activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no 
                    uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser 
                    signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted 
                    that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
                    <br/><br/><br/>
                </p>
                <h1 id="CA-residents">12. Do california residents have specific rights?</h1>
                <p>
                    <br/>
                    <b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
                    <br/><br/>
                    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request 
                    and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties 
                    for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately 
                    preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us 
                    using the contact information provided below.
                    <br/><br/>
                    If you are under 18 years of age, reside in California, and have a registered account with the website, you have the right to request removal 
                    of unwanted data that you publicly post on the website. To request removal of such data, please contact us using the contact information 
                    provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure 
                    the data is not publicly displayed on the website, but please be aware that the data may not be completely or comprehensively removed from all 
                    our systems (e.g. backups, etc.).
                    <br/><br/>
                </p>
                <h2>CCPA Privacy Notice</h2>
                <p>
                    <br/>
                    The California Code of Regulations defines a "resident" as:
                    <br/><br/>
                    <ol>
                        <li>every individual who is in the State of California for other than a temporary or transitory purpose and</li>
                        <li>every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</li>
                    </ol>
                    <br/>
                    All other individuals are defined as "non-residents."
                    <br/><br/>
                    If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.
                    <br/><br/>
                    <b>What categories of personal information do we collect?</b>
                    <br/><br/>
                    We have collected the following categories of personal information in the past twelve (12) months:
                    <br/><br/>
                    <table>
                        <tr>
                            <th>Category</th>
                            <th>Examples</th>
                            <th>Collected</th>
                        </tr>
                        <tr>
                            <td>A. Identifiers</td>
                            <td>
                                Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier 
                                online identifier, Internet Protocol address, email address and account name
                            </td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>B. Personal information categories listed in the California Customer Records statute</td>
                            <td>Name, contact information, education, employment, employment history and financial information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>C. Protected classification characteristics under California or federal law</td>
                            <td>Gender and date of birth</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>D. Commercial information</td>
                            <td>Transaction information, purchase history, financial details and payment information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>E. Biometric information</td>
                            <td>Fingerprints and voiceprints</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>F. Internet or other similiar network activity</td>
                            <td>
                                Browsing history, search history, online behavior, interest data, and interactions with our and other websites, 
                                applications, systems and advertisements
                            </td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>G. Geolocation data</td>
                            <td>Device location</td>
                            <td>YES</td>
                        </tr>
                        <tr>
                            <td>H. Audio, electronic, visual, thermal, olfactory, or similiar information</td>
                            <td>Images and audio, video or call recordings created in connection with our business activities</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>I. Professional or employment-related information</td>
                            <td>
                                Business contact details in order to provide you our services at a business level, 
                                job title as well as work history and professional qualifications if you apply for 
                                a job with us
                            </td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>J. Education Information</td>
                            <td>Student records and directory information</td>
                            <td>NO</td>
                        </tr>
                        <tr>
                            <td>K. Inferences drawn from other personal information</td>
                            <td>
                                Inferences drawn from any of the collected personal information listed above to create a profile 
                                or summary about, for example, an individual's preferences and characteristics
                            </td>
                            <td>NO</td>
                        </tr>
                    </table>
                    <br/><br/>
                    We may also collect other personal information outside of these categories instances where you interact with us in-person, 
                    online, or by phone or mail in the context of:
                    <br/><br/>
                    <ul>
                        <li>Receiving help through our customer support channels;</li>
                        <li>Participation in customer surveys or contests; and</li>
                        <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
                    </ul>
                    <br/>
                    <b>How do we use and share your personal information?</b>
                    <br/><br/>
                    More information about our data collection and sharing practices can be found in this privacy notice and our Cookie 
                    Notice: <Link to="http://www.gtamaxprofit.com/cookie-policy">http://www.gtamaxprofit.com/cookie-policy</Link>.
                    <br/><br/>
                    You may contact us by email at gtamaxprofit@gmail.com, or by referring to the contact details at the bottom of this document.
                    <br/><br/>
                    If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit 
                    proof that they have been validly authorized to act on your behalf.
                    <br/><br/>
                    <b>Will your information be shared with anyone else?</b>
                    <br/><br/>
                    We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. 
                    Each service provider is a for-profit entity that processes the information on our behalf.
                    <br/><br/>
                    We may use your personal information for our own business purposes, such as for undertaking internal research for technological development 
                    and demonstration. This is not considered to be "selling" of your personal data.
                    <br/><br/>
                    GTA Max Profit has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 
                    months. GTA Max Profit will not sell personal information in the future belonging to website visitors, users and other consumers.
                    <br/><br/>
                    <b>Your rights with respect to your personal data</b>
                    <br/><br/>
                    <u>Right to request deletion of the data - Request to delete</u>
                    <br/><br/>
                    You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request 
                    and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another 
                    consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation or any processing that may be 
                    required to protect against illegal activities.
                    <br/><br/>
                    <u>Right to be informed - Request to know</u>
                    <br/><br/>
                    Depending on the circumstances, you have a right to know:
                    <br/><br/>
                    <ul>
                        <li>whether we collect and use your personal information;</li>
                        <li>the categories of personal information that we collect;</li>
                        <li>the purposes for which the collected personal information is used;</li>
                        <li>whether we sell your personal information to third parties;</li>
                        <li>the categories of personal information that we sold or disclosed for a business purpose;</li>
                        <li>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
                        <li>the business or commercial purpose for collecting or selling personal information.</li>
                    </ul>
                    <br/>
                    In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a 
                    consumer request or to re-identify individual data to verify a consumer request.
                    <br/><br/>
                    <u>Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights</u>
                    <br/><br/>
                    We will not discriminate against you if you exercise your privacy rights.
                    <br/><br/>
                    <u>Verification process</u>
                    <br/><br/>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information 
                    in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have 
                    previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that 
                    we can match the information you provide with the information we already have on file, or we may contact you through a communication method 
                    (e.g. phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.
                    <br/><br/>
                    We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent 
                    possible, we will avoid requesting additional information from you for the purposes of verification. If, however, we cannot verify your 
                    identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying 
                    your identity, and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.
                    <br/><br/>
                    <u>Other privacy rights</u>
                    <br/><br/>
                    <ul>
                        <li>you may object to the processing of your personal data</li>
                        <li>you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data</li>
                        <li>
                            you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does 
                            not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
                        </li>
                        <li>
                            you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act 
                            upon the request as soon as feasibly possible, but no later than 15 days from the date of the request submission.
                        </li>
                    </ul>
                    <br/>
                    To exercise these rights, you can contact us by email at gtamaxprofit@gmail.com, or by referring to the contact details at the bottom of this document. 
                    If you have a complaint about how we handle your data, we would like to hear from you.
                    <br/><br/><br/>
                </p>
                <h1 id="policy-updates">13. Do we make updates to this notice?</h1>
                <p>
                    <br/>
                    <b>In Short:</b>  Yes, we will update this notice as necessary to stay compliant with relevant laws.
                    <br/><br/>
                    We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will 
                    be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice 
                    of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are 
                    protecting your information.
                    <br/><br/><br/>
                </p>
                <h1 id="contact">14. How can you contact us about this notice?</h1>
                <p>
                    <br/>
                    If you have any questions or comments about this notice, you may contact us at gtamaxprofit@gmail.com.
                    <br/><br/><br/>
                </p>
                <h1 id="request">15. How can you review, update, or delete the we data we collect from you?</h1>
                <p>
                    <br/>
                    Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that 
                    information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form 
                    by clicking <Link to="https://app.termly.io/notify/eb243b92-f21b-47ab-96aa-018487d6181b" target="_blank">here</Link>.
                    <br/><br/><br/>
                </p>
                <p>
                    This privacy policy was created using <Link to="https://termly.io/products/privacy-policy-generator/?ftseo" target="_blank">Termly's Privacy Policy Generator</Link>.
                </p>
            </section>
        </Layout>
    )
}

export default PrivacyPolicy;